<template>
<div>
  <tinymce-text ref="editor" v-model="value" @input="input"></tinymce-text>
  <el-button @click="save">保存</el-button>
</div>

</template>

<script>

import TinymceText from '../components/TinymceText.vue'
 
export default {
    name:'tinymc',
    data() {
        return{
            value: ''
        }
    },

    methods:{
        save(){
            console.log(this.value);
        },

        input(e) {
            this.value = e
        }

    },
    components:{
        'tinymce-text': TinymceText
    }
}
</script>

<style>

</style>